import './App.css';
import {useRef, useState} from "react";

function App() {
    const inputRef = useRef(null);
    const [link, setLink] = useState('');
  return (
    <div className="App" style={{
        padding: 50,
        height: "80vh"
    }}>
        <input
            ref={inputRef}
            type="text"
            placeholder="Enter the link"
            // onChange={(e) => setLink(e.target.value)}
        />
        <button
            onClick={() => {
                setLink(inputRef.current.value);
            }}>Set link</button>
        <button onClick={()=>{
            setLink('');
    inputRef.current.value = '';

        }}>Clear</button>
      <iframe
          width={"90%"}
          height={"90%"}
          allow='payment'
          src={link}
      />
    </div>
  );
}

export default App;
